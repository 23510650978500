
export const { getData, setData, resetData } = (() => {
  let state = {}

  return {
    getData: () => state,
    setData: (data) => { state = { ...state, ...data } },
    resetData: () => { state = {} }
  }
})()

import React from 'react'
import { JsonForms } from '@jsonforms/react'
import { materialRenderers } from '@jsonforms/material-renderers';
import MultiSelectControlTester from '../../CustomRenderer/MultiSelect/MultiSelectControlTester';
import MultiSelectControl from '../../CustomRenderer/MultiSelect/MultiSelectControl';
import SelectControlTester from '../../CustomRenderer/Select/SelectControlTester';
import SelectControl from '../../CustomRenderer/Select/SelectControl';

const renderers = [
  ...materialRenderers,
  { tester: MultiSelectControlTester, renderer: MultiSelectControl },
  { tester: SelectControlTester, renderer: SelectControl }
]

const Form = ({ dataSchema, uiSchema, data, onChange }) => {
  return (
    <JsonForms
      data={data}
      schema={dataSchema}
      uischema={uiSchema}
      renderers={renderers}
      onChange={onChange}
    />
  )
}

export default Form

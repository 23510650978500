import React from 'react'
import { Route, Routes } from 'react-router-dom'
import ActionController from './pages/Action/ActionController'
import ErrorController from './pages/Error/ErrorController'
import SuccessSubmitController from './pages/SuccessSubmit/SuccessSubmitController'
import CancelActionController from './pages/CancelAction/CancelActionController'

const basePath = '/subdomain-space/v1/:domain/:subdomain'

const thingInclusionPath = `${basePath}/plugins/:plugin/supported-things/:supportedThingId`
const pluginConfigurationPath = `${basePath}/plugins/:plugin/configuration/render`
const thingConfigurationPath = `${basePath}/things/:thing/configuration/render`

const Router = (props) => {
  return (
    <Routes>
      <Route
        exact
        path={`${thingInclusionPath}/inclusions/:actionId/render`}
        element={<ActionController {...props} />}
      />
      <Route
        exact
        path={`${thingInclusionPath}/inclusions/:actionId/render/redirects/success`}
        element={<SuccessSubmitController />}
      />
      <Route
        exact
        path={`${thingInclusionPath}/inclusions/:actionId/render/redirects/error`}
        element={<ErrorController />}
      />
      <Route
        exact
        path={`${thingInclusionPath}/inclusions/:actionId/render/redirects/cancel`}
        element={<CancelActionController />}
      />
      <Route
        exact
        path={`${pluginConfigurationPath}`}
        element={<ActionController {...props} />}
      />
      <Route
        exact
        path={`${pluginConfigurationPath}/redirects/success`}
        element={<SuccessSubmitController />}
      />
      <Route
        exact
        path={`${pluginConfigurationPath}/redirects/error`}
        element={<ErrorController />}
      />
      <Route
        exact
        path={`${pluginConfigurationPath}/redirects/cancel`}
        element={<CancelActionController />}
      />

      <Route
        exact
        path={`${thingConfigurationPath}`}
        element={<ActionController {...props} />}
      />
      
      <Route
        exact
        path={`${thingConfigurationPath}/redirects/success`}
        element={<SuccessSubmitController />}
      />

      <Route
        exact
        path={`${thingConfigurationPath}/redirects/error`}
        element={<ErrorController />}
      />

      <Route
        exact
        path={`${thingConfigurationPath}/redirects/cancel`}
        element={<CancelActionController />}
      />
    </Routes>
  )
}

export default Router

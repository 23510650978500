import React from 'react'
import Alert from '@mui/material/Alert';

const SuccessSubmitView = (props) => {
  return (
    <Alert severity="success">Success</Alert>
  )
}

export default SuccessSubmitView

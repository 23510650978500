import React, { useState, useEffect, useCallback, useMemo } from 'react'
import { array, string, object } from 'prop-types'
import ActionView from './ActionView'
import { getData, setData } from '../../DataContainer'
import { getStepInitData, submit, cancel } from '../../network/api'
import { getRandomHash } from '../../util'
import ErrorRedirect from './ErrorRedirect'
import SuccessRedirect from './SuccessRedirect'
import CancelRedirect from './CancelRedirect'
import LoadingComponent from '../../components/basic/Loading'
import { useLocation } from 'react-router-dom'
import DiscoveryView from '../Discovery/DiscoveryView'
import PluginError from '../../network/PluginError'

const ActionController = (props) => {

  const [stepIndex, setStepIndex] = useState(0)
  const [stepToRender, setStepToRender] = useState(null)
  const [sessionId] = useState(getRandomHash())
  const [error, setError] = useState(null)
  const [submitError, setSubmitError] = useState(null)
  const [goToSuccessPage, setGoToSuccessPage] = useState(false)
  const [goToCancelPage, setGoToCancelPage] = useState(false)
  const [isDiscoveryInProgress, setIsDiscoveryInProgress] = useState(false)
  const [isAsyncOperationInProgress, setIsAsyncOperationInProgress] = useState(false)
  const { pluginContext, steps } = props

  const location = useLocation()

  const discoveryStepUiSchema = useMemo(() => stepToRender?.uiSchema.elements.find(el => el.type === 'Discovery'), [stepToRender])

  const getUrlParamsFromProps = useCallback((step) => {
    return {
      pluginContext,
      stepId: step
    }
  }, [pluginContext])

  const initialize = useCallback(async () => {
    try {
      const initData = await getStepInitData(
        getUrlParamsFromProps(stepIndex),
        sessionId
      )
      setData(initData || {})
      setStepToRender(steps[stepIndex])
    } catch (err) {
      setSubmitError(err.message ? err.message : err)
    }
  }, [setStepToRender, getUrlParamsFromProps, stepIndex, steps, sessionId, setSubmitError])

  useEffect(() => {
    async function init () {
      await initialize()
    }
    init()
  }, [])

  useEffect(() => {
    setIsDiscoveryInProgress(!!discoveryStepUiSchema)
  }, [discoveryStepUiSchema])

  const onSubmit = useCallback(async () => {
    if (getData().errors && getData().errors.length > 0) {
      return
    }

    setIsAsyncOperationInProgress(true)
    
    let presentNextStep
    try {
      const { data } = getData()
      setData(data || {})
      presentNextStep = await submit(
        getUrlParamsFromProps(stepIndex),
        { data, sessionId, step: stepIndex }
      )
    } catch (err) {
      if (err instanceof PluginError && !isDiscoveryInProgress) setError(err.message)
        else setSubmitError(err.message ? err.message : err)
      setIsAsyncOperationInProgress(false)
      return
    }

    if (!presentNextStep) return

    const nextStepIndex = stepIndex + 1
    const nextStep = steps[nextStepIndex]

    if (!nextStep) {
      setGoToSuccessPage(true)
      return
    }

    let nextStepInitData
    try {
      nextStepInitData = await getStepInitData(
        getUrlParamsFromProps(nextStepIndex),
        sessionId
      )
    } catch (err) {
      setSubmitError(err.message ? err.message : err)
      setIsAsyncOperationInProgress(false)
      return
    }

    // setData({ ...getData(), data: nextStepInitData})
    setData(nextStepInitData || {})
    setIsDiscoveryInProgress(!presentNextStep)
    setStepIndex(nextStepIndex)
    setStepToRender(nextStep)
    setError(null)
    setIsAsyncOperationInProgress(false)
  }, [sessionId, steps, stepIndex, getUrlParamsFromProps, setError, setStepIndex, setStepToRender, setGoToSuccessPage, isDiscoveryInProgress])

  const onChange = useCallback(({ errors, data }) => {
    setData({ errors, data })
    // setError(null)
  }, [])

  const onCancel = useCallback(async () => {
    try {
      setIsAsyncOperationInProgress(true)
      await cancel(
        getUrlParamsFromProps(stepIndex),
        { sessionId, step: stepIndex }
      )
    } catch (err) {
      setSubmitError(err.message ? err.message : err)
      setIsAsyncOperationInProgress(false)
      return
    }
    setGoToCancelPage(true)
    setIsAsyncOperationInProgress(false)
  }, [setGoToCancelPage, setSubmitError, getUrlParamsFromProps, stepIndex, sessionId])

  const onBack = useCallback(async () => {
    try {
      setError(null)
      setIsAsyncOperationInProgress(true)
      await cancel(
        getUrlParamsFromProps(stepIndex),
        { sessionId, step: stepIndex }
      )

      const prevStepIndex = stepIndex - 1
      const prevStep = steps[prevStepIndex]

      await getStepInitData(
        getUrlParamsFromProps(prevStepIndex),
        sessionId
      )

      setStepIndex(prevStepIndex)
      setStepToRender(prevStep)
      setIsAsyncOperationInProgress(false)
    } catch (err) {
      setSubmitError(err.message ? err.message : err)
      setIsAsyncOperationInProgress(false)
    }
  }, [setStepIndex, setStepToRender, stepIndex, steps, setSubmitError, sessionId, getUrlParamsFromProps])

  if (submitError) {
    return <ErrorRedirect message={`Something went wrong: ${submitError}`} queryParams={location.search} />
  }

  if (goToSuccessPage) {
    return <SuccessRedirect queryParams={location.search} />
  }

  if (goToCancelPage) {
    return <CancelRedirect queryParams={location.search} />
  }

  // Check if stepToRender is null and display the loading message
  if (!stepToRender) {
    return <LoadingComponent />
  }

  if (isDiscoveryInProgress) {
    return <DiscoveryView onSubmit={onSubmit} onCancel={onCancel} onBack={onBack} discoveryStepUiSchema={discoveryStepUiSchema}/>
  }

  return (
    <ActionView
      stepToRender={stepToRender}
      stepIndex={stepIndex}
      isLastStep={stepIndex === steps.length - 1}
      isFirstStep={stepIndex === 0}
      data={getData()}
      onChange={onChange}
      onSubmit={onSubmit}
      onCancel={onCancel}
      onBack={onBack}
      isAsyncOperationInProgress={isAsyncOperationInProgress}
      error={error}
      theme={props.theme}
    />
  )
}

ActionController.propTypes = {
  pluginContext: object.isRequired,
  steps: array.isRequired,
  theme: string
}

export default ActionController

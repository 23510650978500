import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import { BrowserRouter } from 'react-router-dom'

const props = window.PROPS
ReactDOM.hydrate(
  <React.StrictMode>
    <BrowserRouter>
      <App {...props} />
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
)

import React from 'react'
import Form from '../../basic/Form'
import SubmitButton from './SubmitButton'
import CancelButton from './CancelButton'
import BackButton from './BackButton'
import ErrorMessage from '../../basic/ErrorMessage'
import UndefinedStep from './UndefinedStep'
import { Container, Box } from '@mui/material';

const StepForm = ({ isLastStep, isFirstStep, step, onChange, data, onSubmit, onCancel, onBack, isAsyncOperationInProgress, error }) => {
  if (!step) {
    return <UndefinedStep />
  }

  return (
    <>
      <Container maxWidth="sm" sx={{pt: '10px'}}>
        <Form
          dataSchema={step.dataSchema}
          uiSchema={step.uiSchema}
          data={data}
          onChange={onChange}
        />
        {error && <ErrorMessage error={error} />}

        <Box display='flex' justifyContent='space-between'>
          <BackButton onBack={onBack} isFirstStep={isFirstStep} disabled={isAsyncOperationInProgress}/>
          <Box display='flex' gap={1}>
            <CancelButton onCancel={onCancel} disabled={isAsyncOperationInProgress}/>
            <SubmitButton onSubmit={onSubmit} isLastStep={isLastStep} disabled={isAsyncOperationInProgress}/>
          </Box>
        </Box>
      </Container>
    </>
  )
}

export default StepForm

import React from 'react'
import Button from '../../basic/Button'
import { Box } from '@mui/material'

const BackButton = ({ onBack, isFirstStep, disabled }) => {
  if (isFirstStep) return <Box></Box>

  return (
    <Button onClick={onBack} disabled={disabled}>
      Back
    </Button>
  )
}

export default BackButton

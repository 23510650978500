import React from 'react'
import ErrorView from './ErrorView'
import { useLocation } from 'react-router-dom'

const ErrorController = () => {

  const location = useLocation()

  return <ErrorView message={location.state.errorMessage} />
}

export default ErrorController

import React from 'react'
import Router from './Router'
import { ThemeProvider, CssBaseline } from '@mui/material'
import useTheme from './hooks/useTheme'

const App = (props) => {
  const theme = useTheme()

  return (
    <>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Router {...props} />
        <script dangerouslySetInnerHTML={{
          __html: `window.PROPS=${JSON.stringify(props)}`
        }}
        />
      </ThemeProvider>
    </>
  )
}

export default App

import React, { useEffect } from 'react'
import CancelActionView from './CancelActionView'

const CancelActionController = () => {

  useEffect(() => {
    const messageData = {
      type: "siotActionCancel",
      detail: { message: "Action was canceled!" }
    };

    window.parent.postMessage(messageData, '*');
  }, []);

  return <CancelActionView/>
}

export default CancelActionController

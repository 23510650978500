import { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { createTheme } from '@mui/material'

function isHexColor (str) {
  const hexColorRegex = /^([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3}|[A-Fa-f0-9]{8}|[A-Fa-f0-9]{4})$/
  return hexColorRegex.test(str)
}

const defaultTheme = createTheme()
const { primary, secondary, error, background, text } = defaultTheme.palette

const useTheme = () => {
  const [theme, setTheme] = useState(defaultTheme)

  const location = useLocation()

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search)
    const primaryQuery = searchParams.get('primary')
    const secondaryQuery = searchParams.get('secondary')
    const errorQuery = searchParams.get('error')
    const backgroundQuery = searchParams.get('background')
    const onPrimaryQuery = searchParams.get('onPrimary')
    const onSecondaryQuery = searchParams.get('onSecondary')
    const onBackgroundQuery = searchParams.get('onBackground')
    const inverseSurfaceQuery = searchParams.get('inverseSurface')

    const primaryColor = isHexColor(primaryQuery) ? `#${primaryQuery}` : primary.main
    const onPrimaryColor = isHexColor(onPrimaryQuery) ? `#${onPrimaryQuery}` : primary.contrastText
    const secondaryColor = isHexColor(secondaryQuery) ? `#${secondaryQuery}` : secondary.main
    const onSecondaryColor = isHexColor(onSecondaryQuery) ? `#${onSecondaryQuery}` : secondary.contrastText
    const errorColor = isHexColor(errorQuery) ? `#${errorQuery}` : error.main
    const backgroundColor = isHexColor(backgroundQuery) ? `#${backgroundQuery}` : background.default
    const onBackgroundColor = isHexColor(onBackgroundQuery) ? `#${onBackgroundQuery}` : text.primary
    const inverseSurfaceColor = isHexColor(inverseSurfaceQuery) ? `#${inverseSurfaceQuery}` : '#444'

    setTheme(createTheme({
      palette: {
        primary: {
          main: primaryColor,
          contrastText: onPrimaryColor
        },
        secondary: {
          main: secondaryColor,
          contrastText: onSecondaryColor
        },
        error: {
          main: errorColor
        },
        background: {
          default: backgroundColor,
          paper: backgroundColor
        },
        text: {
          primary: onBackgroundColor,
          secondary: onBackgroundColor
        },
        action: {
          active: primaryColor
        }
      },
      components: {
        MuiInput: {
          styleOverrides: {
            root: {
              '&::before': {
                borderBottomColor: onBackgroundColor
              }
              // '& input[type=number]::-webkit-inner-spin-button': {
              //   WebkitAppearance: 'none',
              //   appearance: 'none',
              //   backgroundColor: 'red',
              //   color: 'green'
              // }
              // '& input::-webkit-scrollbar': {
              //   backgroundColor: 'blue'
              // },
              // '& input::-webkit-scrollbar-thumb': {
              //   backgroundColor: 'red'
              // }
            }
          }
        },
        MuiCssBaseline: {
          styleOverrides: {
            body: {
              scrollbarColor: `${backgroundColor} ${inverseSurfaceColor}`,
              '&::-webkit-scrollbar, & *::-webkit-scrollbar': {
                backgroundColor: inverseSurfaceColor
              },
              '&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb': {
                borderRadius: 8,
                backgroundColor
              }
            }
          }
        },
        MuiSwitch: {
          styleOverrides: {
            root: {
              '&:not(.Mui-checked)': {
                '& .MuiSwitch-thumb': {
                  backgroundColor: secondaryColor
                }
              },
              '& .Mui-checked': {
                '& .MuiSwitch-thumb': {
                  backgroundColor: primaryColor
                }
              },
              '& .MuiSwitch-track': {
                backgroundColor: secondaryColor
              }
            }
          }
        },
        MuiTooltip: {
          styleOverrides: {
            tooltip: {
              backgroundColor: inverseSurfaceColor,
              color: backgroundColor
            }
          }
        }
      }
    }))
  }, [setTheme, location])

  return theme
}

export default useTheme

import React from 'react'
import Button from '../../basic/Button'

const SubmitButton = ({ onSubmit, isLastStep, disabled }) => {
  return (
    <Button onClick={onSubmit} disabled={disabled}>
      {isLastStep ? 'Submit' : 'Next step'}
    </Button>
  )
}

export default SubmitButton

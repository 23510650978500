import React from 'react'
import { Navigate, useLocation } from 'react-router-dom'

const CancelRedirect = ({ queryParams }) => {
  const location = useLocation()
  const successURL = `${location.pathname}/redirects/cancel${queryParams}`

  return <Navigate to={successURL} />
}

export default CancelRedirect

import React, { useEffect } from 'react'
import { Container, Typography, Grid } from '@mui/material';
import CancelButton from '../../components/advance/StepForm/CancelButton'
import BackButton from '../../components/advance/StepForm/BackButton'
import LoadingComponent from '../../components/basic/Loading';

const DiscoveryView = ({ onSubmit, onCancel, onBack, discoveryStepUiSchema }) => {

  useEffect(() => {
    const interval = setInterval(function () {
      onSubmit()
    }, 2000)

    return () => clearInterval(interval)
  }, [onSubmit])

  return (
    <Container maxWidth="sm" sx={{pt: '10px', textAlign: 'center', display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '10px'}}>
      <LoadingComponent/>
      <Typography>{discoveryStepUiSchema?.text}</Typography>
      <Grid container justifyContent='center' gap={1}>
        <BackButton onBack={onBack}/>
        <CancelButton onCancel={onCancel}/>
      </Grid>
    </Container>
  )
}

export default DiscoveryView

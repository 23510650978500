import React from 'react'

const inlineStyle = {
  errorMessage: {
    color: '#f7786b'
  }
}

const ErrorMessage = ({ error }) => {
  return (
    <div>
      <span style={inlineStyle.errorMessage}>{error}</span>
    </div>
  )
}

export default ErrorMessage
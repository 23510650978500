import React from 'react'
import { Navigate, useLocation } from 'react-router-dom'

const ErrorRedirect = ({ message, queryParams }) => {
  const location = useLocation()
  const errorURL = `${location.pathname}/redirects/error${queryParams}`

  return (
    <Navigate
      to={errorURL}
      state={{ errorMessage: message }}
    />
  )
}

export default ErrorRedirect

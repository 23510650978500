import PluginError from './PluginError';

export const get = async (url, headers = {}) => {
  const response = await fetch(url, { method: 'GET', headers })
  return response.json()
}

export const post = async (url, body, headers = {}) => {
  const response = await fetch(url, { method: 'POST', body, headers })
  const data = await response.json()
  if (response.status === 501) throw new PluginError(data)
  if (response.status === 500) throw new Error(data)
  return data
}

import React, { useEffect } from 'react';
import SuccessSubmitView from './SuccessSubmitView';

const SuccessSubmitController = (props) => {

  useEffect(() => {
    const messageData = {
      type: "siotFormSuccessSubmit",
      detail: { message: "Submission was successful!" }
    };

    window.parent.postMessage(messageData, '*');
  }, []);

  return <SuccessSubmitView />;
}

export default SuccessSubmitController;

import React from 'react'
import { Navigate, useLocation } from 'react-router-dom'

const SuccessRedirect = ({ queryParams }) => {
  const location = useLocation()
  const successURL = `${location.pathname}/redirects/success${queryParams}`

  return <Navigate to={successURL} />
}

export default SuccessRedirect

import { withJsonFormsControlProps } from '@jsonforms/react';
import { Box, OutlinedInput, InputLabel, MenuItem, FormControl, Select, Chip } from '@mui/material';
import { useState } from 'react';

function MultiSelectControl({ data, handleChange, path, uischema, required }) {

    const { options } = data
    const { label } = uischema

    const [selectedOptions, setSelectedOptions] = useState([]);

    const onChange = (event) => {
        const { target: { value } } = event;
        handleChange(path, { ...data, value })
        setSelectedOptions(value)
    };

    return (
        <FormControl fullWidth sx={{ mt: '6px', mb: '6px' }} required={required} error={required && selectedOptions.length === 0}>
            <InputLabel>{label}</InputLabel>
            <Select
                multiple
                value={selectedOptions}
                onChange={onChange}
                input={<OutlinedInput label={label} />}
                renderValue={(selected) => (
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                    {selected.map((value) => (
                        <Chip key={value} label={value} color='primary'/>
                    ))}
                    </Box>
                )}
            >
            {options.map((option) => (
                <MenuItem key={option} value={option}>
                    {option}
                </MenuItem>
            ))}
            </Select>
        </FormControl>
    )
}

export default withJsonFormsControlProps(MultiSelectControl);
import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

const LoadingComponent = () => (
  <Box
    display="flex"
    flexDirection="column"
    justifyContent="center"
    alignItems="center"
    height="100%"
    width="100%"
    boxSizing="border-box"
    overflow="hidden"
  >
    <CircularProgress />
  </Box>
);

export default LoadingComponent;
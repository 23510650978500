import { withJsonFormsControlProps } from '@jsonforms/react';
import { InputLabel, MenuItem, FormControl, Select } from '@mui/material';
import { useState } from 'react';

function SelectControl({ data, handleChange, path, uischema, required }) {

    const { options } = data
    const { label } = uischema

    const [selectedOption, setSelectedOption] = useState("");

    const onChange = (event) => {
        const { target: { value } } = event;
        handleChange(path, { ...data, value })
        setSelectedOption(value)
    };

    return (
        <FormControl fullWidth sx={{ mt: '6px', mb: '6px' }} required={required} error={required && !selectedOption}>
            <InputLabel>{label}</InputLabel>
            <Select
                value={selectedOption}
                label={label}
                onChange={onChange}
            >
                {options.map((option) => (
                    <MenuItem key={option} value={option}>
                        {option}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    )
}

export default withJsonFormsControlProps(SelectControl);
import React from 'react'
import Button from '@mui/material/Button';

const BasicButton = ({ onClick, children, styles, variant, disabled }) => {
  return (
    <Button variant={variant || 'contained'} onClick={onClick} sx={styles} disabled={disabled}>
      {children}
    </Button>
  )
}

export default BasicButton

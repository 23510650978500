import React from 'react'
import Alert from '@mui/material/Alert';

const CancelActionView = () => {
  return (
    <Alert severity="success">Canceled</Alert>
  )
}

export default CancelActionView

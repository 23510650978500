import React from 'react'
import StepForm from '../../components/advance/StepForm'

function ActionView ({ stepToRender, isLastStep, isFirstStep, data, onChange, onSubmit, onCancel, onBack, isAsyncOperationInProgress, error, theme }) {
  return (
    <StepForm
      step={stepToRender}
      isLastStep={isLastStep}
      isFirstStep={isFirstStep}
      data={data}
      onChange={onChange}
      onSubmit={onSubmit}
      onCancel={onCancel}
      onBack={onBack}
      isAsyncOperationInProgress={isAsyncOperationInProgress}
      error={error}
    />
  )
}

export default ActionView

import React from 'react'
import Button from '../../basic/Button'

const CancelButton = ({ onCancel, disabled }) => {
  return (
    <Button variant='outlined' onClick={onCancel} disabled={disabled}>
      Cancel
    </Button>
  )
}

export default CancelButton

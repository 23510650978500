import React from 'react'
import Alert from '@mui/material/Alert';

const ErrorView = ({ message }) => {
  return (
    <Alert severity="error">{message}</Alert>
  )
}

export default ErrorView

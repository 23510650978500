import { post } from './request';

const buildUrl = ({ stepId, pluginContext }) => {
  const { domain, subdomain, pluginId, actionId } = pluginContext;
  return `/subdomain-space/v1/${domain}/${subdomain}/plugins/${pluginId}/actions/${actionId}/steps/${stepId}`;
};

const createCancelRequestBody = (pluginContext, params) => {
  const { supportedThingId, type, thingId } = pluginContext;
  const { step, sessionId } = params

  const body = new FormData();
  body.set('step', step);
  body.set('sessionId', sessionId);

  body.set('supportedThingId', supportedThingId);
  body.set('type', type);
  body.set('thingId', thingId);

  return body;
};

const createSubmitRequestBody = (pluginContext, params) => {
  const { data } = params
  const body = createCancelRequestBody(pluginContext, params);
  body.set('data', JSON.stringify(data));
  return body;
};

export const submit = async (urlParams, params) => {
  const url = buildUrl(urlParams);
  const requestBody = createSubmitRequestBody(urlParams.pluginContext, params);
  const response = await post(url, requestBody);
  return response.body;
};

export const getStepInitData = async (urlParams, sessionId) => {
  const url = `${buildUrl(urlParams)}/init-data?sessionId=${sessionId}`;
  const requestBody = createSubmitRequestBody(urlParams.pluginContext, { sessionId });
  const response = await post(url, requestBody);
  return response.body;
};

export const cancel = async (urlParams, params) => {
  const url = `${buildUrl(urlParams)}/cancel`;
  const requestBody = createCancelRequestBody(urlParams.pluginContext, params);
  const response = await post(url, requestBody);
  return response.body;
};

// Function to handle page redirection
export const redirect = (url) => {
  window.location.replace(url);
};